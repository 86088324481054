@font-face {
  font-family: 'Rakkas';
  src: URL('Rakkas-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ConcertOne';
  src: URL('ConcertOne-Regular.ttf') format('truetype');
}

:root {
  --color-bg: #34304c;
  --color-bg2: #534d7a;
  --color-highlight: #673AB7  ;
  --font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  scroll-behavior: smooth;
}

hr {
  background-color: #673AB7;
}

.textHeader {
  font-size: calc(5px + 5vmin);
}

.textSubHeader {
  font-size: calc(5px + 3vmin);
}

.App {
  text-align: center;
  background-color: #EDF0E5;
  color: #8C6C44;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 60vh;
  width: auto;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s ease-in-out;
    animation-direction: alternate;
  }
}

.verticalTextContainer {
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: max-content max-content;
}

.verticalText {
  font-family: "didot";
  font-weight: 900;
  font-size: calc(10px + 10vmin);
  margin: 0;
  writing-mode: vertical-lr;
  text-align: center;
  line-height: .9;
  margin-bottom: 0.8em;
}

.rotateText {
  transform: rotate(180deg);
}

.float{
	height:auto;
	color:#FFF;
	border-radius:50px;
  text-align:center;
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
}

.pulse-button {
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  -webkit-animation: bounce .5s infinite alternate;
  -moz-animation: bounce .5s infinite alternate;
  animation: bounce .5s infinite alternate;
}
.pulse-button:hover {
  animation: none;
}

@-webkit-keyframes bounce {
  to { -webkit-transform: scale(1.3); }
}
@-moz-keyframes bounce {
  to { -moz-transform: scale(1.3); }
}
@keyframes bounce {
  to { transform: scale(1.3); }
}

.App-header {
  min-height: 100vh;
  z-index: 0;
  font-family: Rakkas;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

#showInformationBtn {
  padding: 1000px;
}

.dataText {
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-family: ConcertOne;
  font-size: calc(10px + 2vmin);
}

.goo {
  font-size: calc(1px + 2vmin);
  line-height: 1.35;
  display: inline;
  color: #EDF0E5;
  box-decoration-break: clone;
  background: var(--color-highlight);
  border-radius: 25px;
  padding: 0.5rem 1rem;
}

.goo:focus {
  outline: 0;
}

.App-body {
  font-family: ConcertOne;
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
}

.confirmText {
  font-size: calc(6px + 1vmin);
  color: #EDF0E5;
}

.dropDown {
  min-width: 60vw;
}

input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
}

.form-check-label {
  margin-left: 10px;
  color: #673AB7;
}

.button {
  min-width: 300px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: white;
  border-color: black;
  background: linear-gradient(90deg, rgba(103,58,183,0.7) 0%, rgba(103,58,183,1) 100%);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  }

button::before {
  content: '';
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.button:hover, .button:focus {
  color: white;
  transform: translateY(-6px);
}

button:hover::before, button:focus::before {
  opacity: 1;
}

button::after {
  content: '';
  width: 30px; height: 30px;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button:hover::after, button:focus::after {
  animation: none;
  display: none;
}

@keyframes App-logo-spin {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }
  100% {
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
  }
}
