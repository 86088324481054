.Manage {
    min-height: 100vh;
    z-index: 0;
    font-family: Rakkas;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }